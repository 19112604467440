<template>
  <div>
    {{ title }}
    <el-select
      v-model="searchValue"
      :placeholder="placeholder"
      :filterable="filterable"
      :clearable="clearable"
      :size="size"
      @clear="onClear"
      @change="onChange"
      :style="{ width: width + 'px' }"
    >
      <el-option v-for="item in searchArr" :key="item.id" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    width: {
      type: [String, Number],
      default: '100',
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    value: {
      type: [String, null],
      default: '',
    },
    introductionType: {
      type: String,
      default: '',
    },
    twoIntroductionType: {
      type: String,
      default: '',
    },
    yearValue: {
      type: [String, null],
      default: '',
    },
    twoValue: {
      type: [String, null],
      default: '',
    },
    threeValue: {
      type: [String, null],
      default: '',
    },
    yearIsShow: {
      type: Boolean,
      default: false,
    },
    threeIsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchArr: [],
      searchValue: null,
      dictData: {
        businessLines: [],
        yearList: [],
        projectStatus: [],
      },
    }
  },
  created() {
    this.getType()
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        this.searchValue = val
      },
    },
    list: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.searchArr = val.map(v => {
          return { id: v.id, label: v.dictName, value: v.dictVal }
        })
      },
    },
  },
  methods: {
    async onChange(value) {
      this.$emit('input', value)
      if (!this.type) return
      let res = {}
      let arr = []
      let projectPaymentAmountObj = {}
      let queryParams = `date=${this.yearIsShow ? value : this.yearValue}`
      const addParam = (param, value) => {
        if (value) {
          queryParams += `&${param}=${value}`
        }
      }
      // 父项切换
      if (this.yearIsShow) {
        if (this.twoValue) {
          addParam(this.introductionType, this.twoValue)
          if (this.threeValue) {
            addParam(this.twoIntroductionType, this.threeValue)
          }
        } else if (this.threeValue) {
          addParam(this.twoIntroductionType, this.threeValue)
        }
      } else {
        // 子项切换
        // 正常二级关联，没有三级
        if (value && !this.threeIsShow && !this.threeValue) {
          addParam(this.introductionType, value)
        } else {
          // 三级关联
          if (value && !this.twoValue && this.threeIsShow) {
            addParam(this.twoIntroductionType, value)
          }
          if (this.twoValue && this.threeIsShow) {
            addParam(this.introductionType, this.twoValue)
            if (value) {
              addParam(this.twoIntroductionType, value)
            }
          } else if (this.threeValue && !this.threeIsShow) {
            if (value) {
              addParam(this.introductionType, value)
            }
            addParam(this.twoIntroductionType, this.threeValue)
          }
        }
      }
      if (this.type === 'YearManage') {
        // 年度经营数据
        res = await this.$api.statistics.queryAnnualExecutiveData(queryParams)
        projectPaymentAmountObj = res.data.annualExecutiveDataView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'MonthFinance') {
        // 公司月度财务数据
        res = await this.$api.statistics.queryMonthlyFinanceReport(queryParams)
        projectPaymentAmountObj = res.data.monthlyFinanceDataView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'AddBusiness') {
        // 季度新增商务数据表
        res = await this.$api.statistics.queryNewBusinessDataQuarterly(queryParams)
        const list = res?.data.quarterlyBusinessDataVoList || []
        const year = this.yearIsShow ? value : this.yearValue
        arr = list.filter(v => v.year == year)
        this.$emit('change', arr)
      } else if (this.type === 'ProjectType') {
        // 项目类型分析
        res = await this.$api.statistics.queryProjectCategorySummary(queryParams)
        arr = res.data?.businessCategoryResponseList.map(item => {
          const typeItem = this.dictData.businessLines.find(v => v.dictVal === item.categoryName)
          return {
            categoryPaymentAmount: item.categoryPaymentAmount,
            categoryAmount: item.categoryAmount,
            percentage: Number((item.percentage * 100).toFixed(2)),
            paymentPercentage: Number((item.paymentPercentage * 100).toFixed(2)),
            categoryCount: item.categoryCount,
            name: typeItem ? typeItem.dictName : item.categoryName,
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'ProjectTypeData') {
        // 项目类型分析表
        res = await this.$api.statistics.queryProjectCategorySummaryList(queryParams)
        projectPaymentAmountObj = res.data.businessCategoryResponseView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'ProjectSource') {
        // 项目状态分析
        res = await this.$api.statistics.queryProjectStatusSummary(queryParams)
        arr = res.data?.projectStatusStatisticsVoList.map(item => {
          const typeItem = this.dictData.projectStatus.find(v => v.dictVal === item.projectStatus)
          return {
            projectPaymentAmount: item.projectPaymentAmount,
            paymentPercentage: Number((item.paymentPercentage * 100).toFixed(2)),
            projectAmount: item.projectAmount,
            projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
            projectStatusCount: item.projectStatusCount,
            name: typeItem ? typeItem.dictName : item.projectStatus,
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'projectStatusData') {
        // 项目状态分析表
        res = await this.$api.statistics.queryProjectStatusSummaryList(queryParams)
        projectPaymentAmountObj = res.data.projectStatusStatisticsView || {}
        this.$emit('change', projectPaymentAmountObj)
      } else if (this.type === 'CapitalRisk') {
        // 资金风险监控
        res = await this.$api.statistics.queryFundRiskMonitoring(queryParams)
        arr = res.data?.fundingRiskTypeStatisticsVoList.map(item => {
          return {
            value: item.cost,
            percentage: Number((item.percentage * 100).toFixed(2)),
            typeCount: item.typeCount,
            name: item.fundingRiskName,
          }
        })
        this.$emit('change', arr)
      } else if (this.type === 'CapitalRiskData') {
        // 资金风险监控表
        res = await this.$api.statistics.queryFundRiskMonitoringList(queryParams)
        arr = res.data?.fundingRiskTypeStatisticsVoLists || []
        this.$emit('change', arr)
      } else if (this.type === 'MoneyHistogram') {
        // 公司收支趋势
        res = await this.$api.statistics.queryCompanyRevenueExpenseTrend(queryParams)
        const { companyExpenseResponseVo = {} } = res?.data
        const nameMapping = {
          actualReceiptsList: '收款',
          actualExpenditureList: '支出',
          fundBalanceList: '资金余额',
        }
        arr = Object.keys(companyExpenseResponseVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: companyExpenseResponseVo[key],
          }))
        const contractAmountData = companyExpenseResponseVo.contractPaymentListVoList || []
        this.$emit('change', arr)
        this.$emit('changeData', contractAmountData)
      } else if (this.type === 'ProfitHistogram') {
        // 项目利润趋势图
        res = await this.$api.statistics.queryProjectProfitTrend(queryParams)
        const { projectExpenseResponseVo = {} } = res.data
        const nameMapping = {
          actualExpenditureList: '成本',
          actualReceiptsList: '收入',
          fundBalanceList: '利润',
        }
        arr = Object.keys(projectExpenseResponseVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: projectExpenseResponseVo[key],
          }))
        const projectProfitData = projectExpenseResponseVo.contractPaymentListVoList || []
        this.$emit('change', arr)
        this.$emit('changeData', projectProfitData)
      }
    },

    onClear(value) {
      this.$emit('input', value)
    },
    getType() {
      this.$api.dict
        .listSysDictData('PROJECT_NEW_STATUS', true)
        .then(res => {
          this.dictData.projectStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.fr {
  float: right;
  padding: 3px 0;
}
</style>
